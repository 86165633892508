<template>
  <div class="">
    <div class="px-3">
      <div class="image-upload">
        <div>
          <div class="outer" style="position: relative">
            <figure class="figureClass" ref="figureBlock">
              <img :src="getImage" :key="getImage" class="cursor-pointer" />
            </figure>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <ImageFileComp
          :selectedImageRoot="getImage"
          @selectedImageCallback="selectedImage($event)"
        />
      </div>
    </div>
    <hr />
    <div class="px-3">
      <label for="button-text" class="editor-label">Image Position:</label>
      <div class="d-flex gap text-align-section">
        <label
          title="Left"
          for="left"
          @click="block.style.position = 'start'"
          :class="block.style.position == 'start' ? 'active-editor-align' : ''"
          class="editor-text-left-control"
        >
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6667 0.666626H2.00008C1.64646 0.666626 1.30732 0.807102 1.05727 1.05715C0.807224 1.3072 0.666748 1.64634 0.666748 1.99996V9.99996C0.666748 10.3536 0.807224 10.6927 1.05727 10.9428C1.30732 11.1928 1.64646 11.3333 2.00008 11.3333H12.6667C13.0204 11.3333 13.3595 11.1928 13.6096 10.9428C13.8596 10.6927 14.0001 10.3536 14.0001 9.99996V1.99996C14.0001 1.64634 13.8596 1.3072 13.6096 1.05715C13.3595 0.807102 13.0204 0.666626 12.6667 0.666626ZM12.6667 9.99996H5.33341V1.99996H12.6667V9.99996Z"
              fill="currentColor"
            />
          </svg>
          <span class="select-position-span">Left</span>
        </label>
        <label
          title="Right"
          for=""
          @click="block.style.position = 'end'"
          :class="block.style.position == 'end' ? 'active-editor-align' : ''"
          class="editor-text-center-control"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8335 2.66663H3.16683C2.81321 2.66663 2.47407 2.8071 2.22402 3.05715C1.97397 3.3072 1.8335 3.64634 1.8335 3.99996V12C1.8335 12.3536 1.97397 12.6927 2.22402 12.9428C2.47407 13.1928 2.81321 13.3333 3.16683 13.3333H13.8335C14.1871 13.3333 14.5263 13.1928 14.7763 12.9428C15.0264 12.6927 15.1668 12.3536 15.1668 12V3.99996C15.1668 3.64634 15.0264 3.3072 14.7763 3.05715C14.5263 2.8071 14.1871 2.66663 13.8335 2.66663ZM10.5002 12H3.16683V3.99996H10.5002V12Z"
              fill="currentColor"
            />
          </svg>
          <span class="select-position-span">Right</span>
        </label>
        <label
          for="end"
          title="Center"
          @click="block.style.position = 'center'"
          :class="block.style.position == 'center' ? 'active-editor-align' : ''"
          class="editor-text-right-control"
          style="white-space: nowrap"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="19"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_257_1548)">
              <path
                d="M17.2559 2.74408C17.4769 2.74408 17.6889 2.83187 17.8452 2.98816C18.0014 3.14444 18.0892 3.3564 18.0892 3.57741V16.9107C18.0892 17.1318 18.0014 17.3437 17.8452 17.5C17.6889 17.6563 17.4769 17.7441 17.2559 17.7441H2.25591C2.0349 17.7441 1.82294 17.6563 1.66666 17.5C1.51038 17.3437 1.42258 17.1318 1.42258 16.9107V3.57741C1.42258 3.3564 1.51038 3.14444 1.66666 2.98816C1.82294 2.83187 2.0349 2.74408 2.25591 2.74408H17.2559ZM16.4226 4.41074H3.08925V16.0774H16.4226V4.41074ZM12 6.07741V14H8V6.07741H12Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_257_1548">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span class="select-position-span">Center</span>
        </label>
        <label
          for="end"
          title="Full Width"
          @click="block.style.position = 'fullWidth'"
          :class="
            block.style.position == 'fullWidth' ? 'active-editor-align' : ''
          "
          class="editor-text-right-control"
          style="white-space: nowrap"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 2.66663H2.83333C2.47971 2.66663 2.14057 2.8071 1.89052 3.05715C1.64048 3.3072 1.5 3.64634 1.5 3.99996V12C1.5 12.3536 1.64048 12.6927 1.89052 12.9428C2.14057 13.1928 2.47971 13.3333 2.83333 13.3333H13.5C13.8536 13.3333 14.1928 13.1928 14.4428 12.9428C14.6929 12.6927 14.8333 12.3536 14.8333 12V3.99996C14.8333 3.64634 14.6929 3.3072 14.4428 3.05715C14.1928 2.8071 13.8536 2.66663 13.5 2.66663ZM13.5 12H2.83333V3.99996H13.5V12Z"
              fill="currentColor"
            />
            <rect
              x="3.5"
              y="4.66663"
              width="9.33333"
              height="6.66667"
              fill="currentColor"
            />
          </svg>
          <span class="select-position-span">Full</span>
        </label>
      </div>
      <div class="d-flex justify-content-between mt-2">
        <label for="button-text" class="editor-label"
          >Use separate image for mobile:</label
        >
        <b-form-checkbox
          :id="`add-a-mobile-image-${Date.now()}`"
          v-model="block.useMobileImage"
        ></b-form-checkbox>
      </div>
    </div>
    <hr />
    <div class="px-3">
      <div class="d-flex gap">
        <div
          class="d-flex align-items-center"
          title="Width (px)"
          style="gap: 4px"
        >
          <span class="" style="color: #b3afb6">W</span>
          <input
            type="number"
            v-if="isDesktopView"
            v-model="block.style.desktopWidth"
            placeholder="Width"
            class="editor-input-control w-100 input-bg-color border-0"
          />
          <input
            type="number"
            v-else
            v-model="block.style.mobileWidth"
            placeholder="Width"
            class="editor-input-control w-100 input-bg-color border-0"
          />
        </div>
        <div
          class="d-flex align-items-center"
          title="Height (px)"
          style="gap: 4px"
        >
          <span class="" style="color: #b3afb6">H</span>
          <input
            type="number"
            v-if="isDesktopView"
            v-model="block.style.desktopHeight"
            placeholder="Height"
            class="editor-input-control w-100 input-bg-color border-0"
          />
          <input
            type="number"
            v-else
            v-model="block.style.mobileHeight"
            placeholder="Height"
            class="editor-input-control w-100 input-bg-color border-0"
          />
        </div>
      </div>
      <div class="d-flex mt-2 justify-content-between align-items-center">
        <span class="editor-label">Image Size:</span>
        <div class="select-per-page-count">
          <select
            aria-label="Default select example"
            v-model="block.style.backgroundSize"
          >
            <option value="contain">Fit</option>
            <option value="cover">Fill</option>
          </select>
        </div>
      </div>
      <div class="d-flex mt-2 justify-content-between mt-2 align-items-center">
        <label for="" class="editor-label"> Border Radius: </label>
        <div class="select-per-page-count w-25 d-flex between">
          <div class="">
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                fill="#B3AFB6"
              />
            </svg>
          </div>
          <div class="">
            <input
              type="number"
              :min="0"
              class="w-100"
              v-model.number="block.style.borderRadius"
              id=""
            />
          </div>
        </div>
      </div>
      <div class="">
        <SelectColor
          :label="'Background Color:'"
          :isOpacity="false"
          v-model="block.style.backgroundColor"
        ></SelectColor>
      </div>
    </div>
    <hr>
    <div class="px-3 mb-3">
      <padding
        v-if="isDesktopView"
        :right.sync="block.style.marginRight"
        :left.sync="block.style.marginLeft"
        :top.sync="block.style.marginTop"
        :bottom.sync="block.style.marginBottom"
      ></padding>
      <padding
        v-else
        :label="'Spacing: (Mobile)'"
        :right.sync="block.style.mobileMarginRight"
        :left.sync="block.style.mobileMarginLeft"
        :top.sync="block.style.mobileMarginTop"
        :bottom.sync="block.style.mobileMarginBottom"
      ></padding>
    </div>
  </div>
</template>
<script>
import ImageFileComp from "../../ImageFileComp.vue";
import Padding from "../MiniComponents/Padding.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";

export default {
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
  },
  components: {
    ImageFileComp,
    SelectColor,
    Padding,
  },
  methods: {
    selectedImage(image) {
      if (!image) return;

      const useMobileImage = !!this.block.useMobileImage;

      if (this.isDesktopView || !useMobileImage) {
        this.$set(this.block, "imageSrc", image);
      } else {
        this.$set(this.block, "mobileSrc", image);
      }
    },
  },
  computed: {
    getImage() {
      if (!this.block.imageSrc || this.block.imageSrc === "") {
        return `https://images.quizell.com/website/default_start_page.png`;
      }

      if (this.isDesktopView) {
        return this.block.imageSrc;
      }

      return this.block.useMobileImage &&
        this.block.mobileSrc &&
        this.block.mobileSrc !== ""
        ? this.block.mobileSrc
        : this.block.imageSrc;
    },
  },
};
</script>
<style scoped>
.image-upload {
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background-color: rgb(227, 227, 227);
  margin-bottom: 16px;
  overflow: hidden;
}

.figureClass {
  height: 198px;
  line-height: 0;
  margin: 0px;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.figureClass >>> img {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}
.text-align-section label span {
  display: flex;

  align-items: center;
  justify-items: center;
}
.text-align-section label {
  min-width: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}
</style>